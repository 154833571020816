"use client";

import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { Button, IconButton } from "ui/components/buttons";

export default function GlobalError({ error, }: { error: Error & { digest?: string; }; reset: () => void; }) {
  const router = useRouter();

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <div id="error" className="page">
      <div className="tile">
        <IconButton icon="stop" form="circle" type="error-light" size={80} />
        <h1 className="text-center">Quelque chose ne fonctionne pas</h1>
        <p className="subtitle text-center"> Cliquez sur le bouton ci-dessous pour revenir à la page principale</p>
        <Button icon="return" cb={() => router.push("/auth/connexion")}>
          Revenir sur la page d&apos; accueil
        </Button>
      </div>
    </div>
  );
}
